/* CustomDataGrid.css */

body,
html,
div,
table,
th,
td,
button,
h1 {
  font-family: Arial, sans-serif;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

th {
  background-color: #f2f2f2;
  color: black;
}

tr:nth-child(even) {
  background-color: #f9f9f9;
}

tr:hover {
  background-color: #ddd;
}

button {
  padding: 8px 16px;
  margin: 0 5px;
  cursor: pointer;
  border: none;
  background-color: #4caf50;
  color: white;
  font-size: 14px;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

button:hover:enabled {
  background-color: #45a049;
}
